import {MobileBasePage} from '../../core/base/mobile-base-page';
import {Component, Injector, ViewChild} from '@angular/core';
import {NavParams} from '@ionic/angular';

/**
 * Generated class for the Passcode page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'page-passcode',
    templateUrl: 'passcode.html',
    styleUrls: ['passcode.scss']
})
export class PasscodeDialog extends MobileBasePage {

    passcode: string;
    password: string;
    wrong = false;
    @ViewChild('input') myInput;

    constructor(private injector: Injector, public navParams: NavParams) {
        super(injector);
        this.password = this.navParams.get('passcode');
        console.log('construct PasscodeDlg');
    }

    dismissClicked() {
        this.modalController.dismiss(null);
        console.log('dismiss?');
    }

    submit() {
        if (this.passcode == this.password) {
            this.modalController.dismiss(this.passcode);
        } else {
            this.wrong = true;
            this.passcode = '';
            this.myInput.setFocus();
        }
    }


}
