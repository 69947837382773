var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Data } from 'aigens-ng-core';
import { MobileBasePage } from './mobile-base-page';
import { ModifierDialog } from '../../dialogs/modifier/modifier';
import { Injector } from '@angular/core';
var MobileBaseItemPage = /** @class */ (function (_super) {
    __extends(MobileBaseItemPage, _super);
    function MobileBaseItemPage(injector) {
        var _this = _super.call(this, injector) || this;
        _this.groupIdMap = {};
        _this.isAutoPickup = false;
        _this.isGroupCompleted = {};
        _this.isGroupSelected = {};
        _this.builderGroupsCount = 0;
        _this.itemMap = {};
        _this.total = 0;
        _this.openSelectedBox = false;
        _this.endMove = false;
        _this.preselectItem = false;
        _this.modifiable = false;
        _this.missing = false;
        _this.modeMap = new Map();
        _this.autoPickupGroupIndexs = [];
        _this.mainItemAndAutoPickupGroupIndex = [];
        _this.requiredModifierSelectedMap = {};
        _this.hasModifierMap = {};
        _this.isUpselling = false;
        console.log('set select page construction');
        _this.edit = _this.getNavParams('edit');
        _this.store = _this.getNavParams('store');
        _this.isUpselling = !!_this.getNavParams('upSelling');
        if (_this.store) {
            _this.preselectItem = _this.settings.preselectItem;
            _this.isAutoPickup = _this.settings.autoPickup;
        }
        return _this;
    }
    MobileBaseItemPage.prototype.isAutoPickupGroup = function (group) {
        return false;
    };
    MobileBaseItemPage.prototype.modifierClicked = function (indexs) {
        if (indexs === void 0) { indexs = []; }
        // console.log("modifiable itemGroupMap", this.modifiableItems);
        var ois = this.builder.build();
        this.pushByName('ModifierItemSelectPage', { store: this.store, orderitem: ois, mainItemAndAutoPickupGroupIndex: indexs });
    };
    MobileBaseItemPage.prototype.showModifierPopup = function (g) {
        var _this = this;
        var oi = this.builder.build();
        var index = this.groupIdMap[g.id];
        var group = oi.groups[index];
        if (this.hasModifier(group.items, this.itemMap)) {
            this.presentModifierPopup(this.store, oi, group, this.itemMap, ModifierDialog, function (result) {
                _this.handleModifier();
            });
        }
    };
    MobileBaseItemPage.prototype.numberToFixed = function (number) {
        if ('HKD' === this.orderService.currency) {
            return Number(number.toFixed(1));
        }
        else if ('CNY' === this.orderService.currency) {
            return Number(number.toFixed(1));
        }
        else if ('SGD' === this.orderService.currency) {
            return Number(number.toFixed(2));
        }
        else {
            return Number(number.toFixed(1));
        }
    };
    MobileBaseItemPage.prototype.getSelectRules = function (igroup) {
        if (!igroup || isNaN(igroup.max) || isNaN(igroup.min))
            return '';
        var min = Number(igroup.min);
        var max = Number(igroup.max);
        if (min === max && min === 0)
            return '';
        // 請選擇{{count}}項
        if (min === max)
            return this.t.instant('pages.item-select.select-items', { count: min });
        // 加配項目
        if (min === 0 && max === 99)
            return this.t.instant('pages.item-select.optional');
        // 請選最多max項
        if (min === 0 && max !== 99)
            return this.t.instant('pages.item-select.chooseAtMost', { max: max });
        // 請選至少{{min}}項
        if (min !== 0 && max === 99)
            return this.t.instant('pages.item-select.chooseUp', { min: min });
        // 請選{{min}} - {{max}}項
        if (min < max && max !== 99)
            return this.t.instant('pages.item-select.chooseTo', { min: min, max: max });
        return '';
    };
    MobileBaseItemPage.prototype.matchingGroupIndex = function (oi) {
        var groups = oi.groups;
        if (groups.length !== this.builderGroupsCount && groups.length - 1 <= this.groups.length) {
            this.groupIdMap = {};
            for (var i = 0; i < groups.length; i++) {
                this.groupIdMap[groups[i].id] = i;
            }
        }
        this.builderGroupsCount = groups.length;
    };
    MobileBaseItemPage.prototype.getGroupsByIndex = function (indexs) {
        var indexSet = new Set(indexs);
        var oi = this.currentOrderItem ? this.currentOrderItem : this.builder.build();
        var groups = oi.groups.filter(function (group, index) {
            return indexSet.has(index);
        });
        return groups;
    };
    MobileBaseItemPage.prototype.areRequiredModifierSelectByIndex = function (indexs, map) {
        var groups = this.getGroupsByIndex(indexs);
        return this.areRequiredModifierSelect(groups, map);
    };
    MobileBaseItemPage.prototype.ionViewWillEnter = function () {
        _super.prototype.ionViewWillEnter.call(this);
        this.handleModifier();
    };
    MobileBaseItemPage.prototype.updateSelectedModifier = function () {
        var _this = this;
        var oi = this.builder.build();
        var modifiers = [];
        var total = 0;
        oi.groups.forEach(function (group) {
            var items = group.items;
            items.forEach(function (item) {
                total = _this.numberToFixed(total + item.price * item.quantity);
            });
            items
                .filter(function (item) { return item.mgroups && item.mgroups.length > 0; })
                .forEach(function (item) {
                var mgroups = item.mgroups;
                mgroups.forEach(function (mgroup) {
                    modifiers = modifiers.concat(mgroup.items);
                });
            });
        });
        modifiers = modifiers.filter(function (item) { return item.quantity > 0; });
        modifiers.forEach(function (item) {
            total = _this.numberToFixed(total + item.price * item.quantity);
        });
        if (modifiers && modifiers.length > 0) {
            this.hasSelectedModifiers = true;
            this.selectedModifiersName = modifiers.map(function (item) { return item.name; }).join(',');
        }
        else {
            this.hasSelectedModifiers = false;
            this.selectedModifiersName = null;
        }
        console.log('update price');
        this.total = total;
    };
    MobileBaseItemPage.prototype.handleModifier = function () {
        var oi = this.builder.build();
        this.currentOrderItem = oi;
        var modifiable = this.modifierBuilder.findItems(oi);
        this.modifiable = modifiable.length > 0;
        this.matchingGroupIndex(this.currentOrderItem);
        this.checkingStatus();
        console.log('modifiable?', this.modifiable, modifiable);
        this.updateSelectedModifier();
    };
    MobileBaseItemPage.prototype.checkingStatus = function () {
        console.log('check status~');
    };
    MobileBaseItemPage.prototype.areGroupsHaveModifierByIndex = function (indexs, map) {
        var groups = this.getGroupsByIndex(indexs);
        return this.areGroupsHaveModifier(groups, map);
    };
    MobileBaseItemPage.prototype.areGroupsHaveModifier = function (groups, map) {
        var haveModifier = false;
        for (var _i = 0, groups_1 = groups; _i < groups_1.length; _i++) {
            var group = groups_1[_i];
            var tmpHaveModifier = this.isCurrentGroupHasModifier(group, map);
            if (map) {
                map[group.id] = tmpHaveModifier;
            }
            if (tmpHaveModifier) {
                haveModifier = tmpHaveModifier;
            }
        }
        return haveModifier;
    };
    MobileBaseItemPage.prototype.isCurrentGroupHasModifier = function (g, map) {
        var index = this.groupIdMap[g.id];
        var group = this.currentOrderItem && this.currentOrderItem.groups ? this.currentOrderItem.groups[index] : null;
        if (group) {
            if (map) {
                map[group.id] = this.hasModifier(group.items, this.itemMap);
            }
            var g_1 = this.currentOrderItem && this.currentOrderItem.groups ? this.currentOrderItem.groups[index] : null;
            if (g_1) {
                if (map) {
                    map[g_1.id] = this.hasModifier(g_1.items, this.itemMap);
                }
                return this.hasModifier(g_1.items, this.itemMap);
            }
            else {
                return false;
            }
        }
    };
    MobileBaseItemPage.prototype.addToCartClicked = function () {
        var _this = this;
        if (this.openSelectedBox) {
            this.googleAnalyticEventTrack('add to cart', 'open selected');
        }
        this.googleAnalyticEventTrack('add to cart');
        console.log(this.groups);
        if (!this.isCompleted()) {
            this.showNotCompletedAlert();
            return;
        }
        var oi = this.builder.build();
        oi.combo = true;
        var orderManager;
        if (this.multOrderManager.enableMutlOrder) {
            orderManager = this.multOrderManager.getOrderManager(this.store.id);
        }
        else {
            orderManager = this.orderManager;
        }
        var handler = this.getNavParams('handler');
        if (!handler) {
            if (this.edit) {
                // this.orderService.replaceOrderItem(this.edit, this.category, igs);
                orderManager.replaceOrderItem(this.edit, oi);
            }
            else {
                // this.orderService.addSet(this.category, igs);
                orderManager.addOrderItem(oi);
            }
        }
        if (!this.edit && !this.isUpselling && (this.settings.quickCheckout || handler)) {
            this.pushByName(this.multOrderManager.enableMutlOrder ? 'MultiOrderCartPage' : 'CartPage', null, { replaceUrl: true }).then(function () {
                // find index of home page, and remove from home page
                // let page: any;
                var catLayout = _this.settings.catLayout;
                // if (this.orderService.isBK) {
                //     page = CategoryItemListPage;
                // } else {
                //     let noneBKPage: any;
                //     if (catLayout === 'showAll') {
                //         noneBKPage = CategoryItemListPage;
                //
                //     } else if (catLayout === 'sideMenu') {
                //         noneBKPage = SideMenuCategoryListPage;
                //
                //     } else if (!catLayout || catLayout === 'none') {
                //
                //         noneBKPage = ItemGridPage;
                //
                //     } else {
                //
                //         noneBKPage = CategoryListPage;
                //     }
                //
                //     page = this.edit ? (this.multOrderManager.enableMutlOrder ? BrandStoreListPage : noneBKPage) : noneBKPage;
                // }
                // TODO: remove history
                // let index: number = this.navController.getViews().findIndex(
                //     view => (view.instance instanceof (page))
                // );
                // let current: number = this.navController.getActive().index;
                // console.log('views:' + this.navController.getViews()[0]);
                // console.log('index:' + index);
                // console.log('current:' + current);
                // this.navController.remove(index + 1, (current - 1) - index);
                if (handler) {
                    handler(oi);
                }
            });
        }
        else {
            if (!this.edit) {
                this.showToast(this.t.instant('global.item-added'), 600, "bottom", 'add-item-toast');
            }
            // this.navController.pop();
            // this.navController.setDirection('back');
            this.navigationService.popPage();
        }
    };
    MobileBaseItemPage.prototype.totalQuantity = function (groupIndex) {
        /* let count: number = 0;
        if (this.quantityMap) {
            for (let id in this.quantityMap) {
                count += this.quantityMap[id];
            }
        }
        return count; */
        return this.builder.getChoices(groupIndex);
    };
    MobileBaseItemPage.prototype.isCompleted = function () {
        var completed = true;
        var groups = this.builder.groups;
        // index 0 is main group, which must has an selected item
        for (var i = 0; i < this.groups.length; i++) {
            /*
            * Suppose now there are 5 groups and they are [A, B, C, D, E] and A is the main group,
            * this.groups is an array [B, C, D, E],
            * builder.groups is an array with groups which have selected itemGroupMap, says [A, B, C, (null), (null)]
            *
            * then we check this.groups one by one (except main group)
            * if this.groups[i] is not optional
            * builder.groups[i + 1] must has an selected item
            * if not, then it is incompleted
            */
            var realGroup = this.groups[i];
            var builderGroup = groups[i + 1];
            // console.log("realGroup" ,realGroup);
            // console.log("builderGroup", builderGroup);
            // if (((!realGroup.optional && !realGroup.modifier) || realGroup.min > 0)) {
            //     if (!builderGroup || !this.hasSelectedItems(i + 1) || this.totalQuantity(i + 1) < realGroup.min) {
            //         completed = false;
            //         break;
            //     }
            // }
            if (!realGroup.optional) {
                if (realGroup.min > 0) {
                    if (!builderGroup || !this.hasSelectedItems(i + 1) || this.totalQuantity(i + 1) < realGroup.min) {
                        completed = false;
                        break;
                    }
                }
                // else
                // {
                //     if (!builderGroup || !this.hasSelectedItems(i + 1) || this.totalQuantity(i + 1) < realGroup.min) {
                //         completed = false;
                //         break;
                //     }
                // }
            }
            else {
                if (realGroup.skippable === false) {
                    // for some extreme cases, itemgroup is optional but the min > 0
                    if (this.selectedNoNeed(i + 1)) {
                        // if they selected no need, then shoud pass
                    }
                    else if (builderGroup) {
                        if (this.hasSelectedItems(i + 1)) {
                            if (this.totalQuantity(i + 1) < realGroup.min) {
                                completed = false;
                                break;
                            }
                        }
                        else {
                            completed = false;
                            break;
                        }
                    }
                    else {
                        completed = false;
                        break;
                    }
                }
                else {
                    if (this.selectedNoNeed(i + 1)) {
                        // if they selected no need, then shoud pass
                    }
                    else if (builderGroup) {
                        if (this.hasSelectedItems(i + 1)) {
                            if (this.totalQuantity(i + 1) < realGroup.min) {
                                completed = false;
                                break;
                            }
                        }
                        else {
                        }
                    }
                    else {
                    }
                    // else if (!builderGroup && this.hasSelectedItems(i + 1)) {
                    //     if (this.totalQuantity(i + 1) < realGroup.min) {
                    //         completed = false;
                    //         break;
                    //     }
                    // }
                }
                // completed = this.isRequiredModifierSelect(i);
                // if (!completed) {
                //     break;
                // }
            }
            // else
            // {
            //     if (!builderGroup || !this.hasSelectedItems(i + 1) || this.totalQuantity(i + 1) < realGroup.min) {
            //         completed = false;
            //         break;
            //     }
            // }
            completed = this.isRequiredModifierSelect(i);
            if (!completed) {
                break;
            }
        }
        return completed;
    };
    MobileBaseItemPage.prototype.showNotCompletedAlert = function () {
        return __awaiter(this, void 0, void 0, function () {
            var subtitle, confirm, alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        subtitle = this.t.instant('pages.set-select.select-error');
                        confirm = this.t.instant('buttons.ok');
                        return [4 /*yield*/, this.alertController.create({
                                message: subtitle,
                                buttons: [confirm]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    MobileBaseItemPage.prototype.hasSelectedItems = function (i) {
        var itemGroup = this.builder.groups[i];
        if (!itemGroup) {
            return false;
        }
        var selected = itemGroup.items;
        if (!selected) {
            return false;
        }
        return selected.filter(function (item) { return item.quantity > 0; }).length > 0;
    };
    MobileBaseItemPage.prototype.getSelectedItemNames = function (i) {
        // console.log("check get selected");
        var itemGroup = this.builder.groups[i];
        // console.log("itemG", itemGroup);
        if (itemGroup === null) {
            return this.t.instant('pages.item-select.no-need');
            // return "不需要";
        }
        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }
        if (!itemGroup) {
            return '';
        }
        var selected = itemGroup.items;
        if (!selected) {
            return '';
        }
        return selected
            .filter(function (item) { return item.quantity > 0; })
            .map(function (item) {
            var qtyPart = '';
            if (item.quantity > 1) {
                qtyPart = ' x' + item.quantity;
            }
            return (item.name + qtyPart);
        })
            .join(',');
    };
    MobileBaseItemPage.prototype.presentModifierPopup = function (store, orderItem, group, itemMap, page, handler, showLastOne) {
        return __awaiter(this, void 0, void 0, function () {
            var input, modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        input = { store: store, orderitem: orderItem, group: group, itemMap: itemMap };
                        if (showLastOne) {
                            input['showLastOne'] = showLastOne;
                        }
                        return [4 /*yield*/, this.modalController.create({
                                component: page,
                                componentProps: input,
                                animated: true,
                                showBackdrop: true,
                                backdropDismiss: true,
                                cssClass: 'modal-largescreen modifier-modal'
                            })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (confirm) {
                            if (handler) {
                                handler(confirm);
                            }
                        });
                        modal.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    MobileBaseItemPage.prototype.areRequiredModifierSelect = function (groups, map) {
        var finalResult = true;
        for (var _i = 0, groups_2 = groups; _i < groups_2.length; _i++) {
            var group = groups_2[_i];
            var selected = this.isRequiredModifierSelectByGroup(group);
            if (map) {
                map[group.id] = selected;
            }
            if (!selected) {
                finalResult = selected;
            }
        }
        return finalResult;
    };
    MobileBaseItemPage.prototype.hasRequiredModifier = function (items, itemMap) {
        return this.hasModifier(items, itemMap, true);
    };
    MobileBaseItemPage.prototype.hasModifier = function (items, itemMap, checkRequired) {
        if (checkRequired === void 0) { checkRequired = false; }
        var required = false;
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            var tmpItem = itemMap[item.id];
            if (tmpItem && tmpItem.mgroups && tmpItem.mgroups.length > 0) {
                if (checkRequired) {
                    for (var _a = 0, _b = tmpItem.mgroups; _a < _b.length; _a++) {
                        var mgroup = _b[_a];
                        if (mgroup.min && mgroup.min > 0) {
                            required = true;
                            break;
                        }
                    }
                }
                else {
                    required = true;
                    break;
                }
            }
        }
        return required;
    };
    MobileBaseItemPage.prototype.isRequiredModifierSelectByGroup = function (group) {
        var index = this.groupIdMap[group.id] - 1;
        var tmp = this.isRequiredModifierSelect(index);
        return tmp;
    };
    MobileBaseItemPage.prototype.isRequiredModifierSelect = function (index) {
        var _this = this;
        var oi = this.currentOrderItem ? this.currentOrderItem : this.builder.build();
        this.currentOrderItem = oi;
        var tmpIndex = index + 1;
        var group = oi.groups[tmpIndex];
        if (group && this.hasRequiredModifier(group.items, this.itemMap)) {
            var builderItems = group.items;
            var selected = !builderItems
                .some(function (item) {
                var builderMgroups = item.mgroups;
                var mgroups = _this.itemMap[item.id].mgroups;
                if (mgroups) {
                    for (var i = 0; i < mgroups.length; i++) {
                        if (builderMgroups && builderMgroups[i] && mgroups[i].min > 0) {
                            var quantity = 0;
                            for (var _i = 0, _a = builderMgroups[i].items; _i < _a.length; _i++) {
                                var t = _a[_i];
                                quantity += 1 * t.quantity;
                            }
                            return !(quantity >= mgroups[i].min);
                        }
                        else if ((!builderMgroups || !builderMgroups[i])) {
                            return mgroups[i].min > 0;
                        }
                    }
                }
                else {
                    return false;
                }
            });
            return selected;
        }
        else {
            return true;
        }
    };
    MobileBaseItemPage.prototype.getSelectedItemNamesModifier = function (i) {
        // console.log("check get selected modifier");
        var itemGroup = this.builder.groups[i];
        // if (itemGroup === null) {
        //     return this.t.instant("pages.item-select.no-need");
        //     // return "不需要";
        // }
        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }
        if (!itemGroup) {
            return '';
        }
        var selected = itemGroup.items;
        if (!selected) {
            return '';
        }
        // get modifier
        var firstItem = true;
        var output = '';
        for (var _i = 0, selected_1 = selected; _i < selected_1.length; _i++) {
            var select = selected_1[_i];
            if (!select.mgroups) {
                continue;
            }
            for (var _a = 0, _b = select.mgroups; _a < _b.length; _a++) {
                var mgroup = _b[_a];
                if (!mgroup.items) {
                    continue;
                }
                for (var _c = 0, _d = mgroup.items; _c < _d.length; _c++) {
                    var item = _d[_c];
                    if (firstItem) {
                        output = item.name + ' x ' + item.quantity;
                        firstItem = false;
                    }
                    else {
                        output = output + ', ' + item.name + ' x ' + item.quantity;
                    }
                }
                // return output;
            }
        }
        return output;
    };
    MobileBaseItemPage.prototype.getSelectedItemsModifierPrice = function (items) {
        var price = 0;
        for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
            var select = items_2[_i];
            if (!select.mgroups) {
                continue;
            }
            for (var _a = 0, _b = select.mgroups; _a < _b.length; _a++) {
                var mgroup = _b[_a];
                if (!mgroup.items) {
                    continue;
                }
                for (var _c = 0, _d = mgroup.items; _c < _d.length; _c++) {
                    var item = _d[_c];
                    price = price + item.price * item.quantity;
                }
            }
        }
        return price;
    };
    MobileBaseItemPage.prototype.selectedNoNeed = function (i) {
        var itemGroup = this.builder.groups[i];
        if (itemGroup === null) {
            return true;
        }
        // if (typeof itemGroup === 'undefined') {
        //     return false;
        // }
        return false;
    };
    MobileBaseItemPage.prototype.getSelectedItemsPrice = function (i) {
        var itemGroup = this.builder.groups[i];
        if (itemGroup === null) { // no need
            return this.orderManager.store.currencySign + '0';
        }
        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }
        if (!itemGroup) {
            return '';
        }
        var selected = itemGroup.items;
        if (!selected) {
            return '';
        }
        var price = 0;
        selected.forEach(function (item) {
            price = price + item.price * item.quantity;
        });
        if (this.isCurrentGroupHasModifier(itemGroup)) {
            price = price + this.getSelectedItemsModifierPrice(selected);
        }
        return this.orderManager.store.currencySign + price.toString();
    };
    MobileBaseItemPage.prototype.setMode = function () {
        var _this = this;
        this.groups.forEach(function (group, index) {
            if (group.repeat) {
                _this.modeMap.set(index + 1, 'quantity');
            }
            else {
                _this.modeMap.set(index + 1, 'checkbox');
            }
        });
    };
    MobileBaseItemPage.prototype.checkingGroupCompleted = function (index) {
        // if (isNaN(index)) return;
        if (isNaN(index) || (!index && !isNaN(index))) {
            return;
        }
        var groupId = this.groups[index - 1].id;
        var min = this.groups[index - 1].min;
        var max = this.groups[index - 1].max;
        var currentQty = this.totalQuantity(index);
        // if (min === max) {
        this.isGroupSelected[groupId] = currentQty > 0 || this.selectedNoNeed(index);
        this.isGroupCompleted[groupId] = currentQty === max || currentQty >= min || this.selectedNoNeed(index);
        var itemGroup = this.builder.groups[index];
        if (this.groups[index - 1] && this.groups[index - 1].optional) {
            if (this.groups[index - 1].skippable) {
                this.isGroupCompleted[groupId] = true;
            }
            else {
                if (this.selectedNoNeed(index)) {
                    this.isGroupCompleted[groupId] = true;
                }
                else if (this.hasSelectedItems(index)) {
                    if (this.totalQuantity(index) < this.groups[index - 1].min) {
                        this.isGroupCompleted[groupId] = false;
                    }
                    else {
                        this.isGroupCompleted[groupId] = true;
                    }
                }
                else {
                    this.isGroupCompleted[groupId] = false;
                }
            }
        }
        // console.log(groupId);
        // console.log(min);
        // console.log(max);
        // console.log(currentQty);
        // console.log(this.isGroupSelected[groupId]);
        // console.log(this.isGroupCompleted[groupId]);
        return this.isGroupCompleted[groupId] || this.selectedNoNeed(index);
    };
    MobileBaseItemPage.prototype.handlingModifier = function (oi, realGroup, itemMap, addHandler, notAddHandler, showLastOne) {
        if (showLastOne === void 0) { showLastOne = false; }
        if (!oi) {
            return;
        }
        var group = oi.groups.find(function (g) { return g.id === realGroup.id; });
        if (!oi) {
            return;
        }
        group = oi.groups.find(function (g) { return g.id === realGroup.id; });
        if (this.hasRequiredModifier(group.items, this.itemMap)) {
            this.presentModifierPopup(this.store, oi, group, itemMap, ModifierDialog, function (confirm) {
                if (confirm && confirm['data']) {
                    addHandler();
                }
                else {
                    notAddHandler(group);
                }
            }, showLastOne);
        }
        else {
            addHandler();
        }
    };
    MobileBaseItemPage.prototype.getImage = function (data, name, width, crop) {
        if (!data.images)
            return null;
        var im = data.images[name];
        if (!im)
            return null;
        var url = im.url;
        if (!url)
            return null;
        return this.getImageUrl_(url, width, crop);
    };
    MobileBaseItemPage.prototype.getImageUrl_ = function (url, width, crop) {
        if (!url)
            return null;
        width = Math.floor(width);
        if (url.indexOf('googleuser') > 0 && width) {
            var suffix = '=s' + width;
            if (crop) {
                suffix += '-c';
            }
            url += suffix;
        }
        else if ((url.indexOf('content.aigens.com.cn') > 0 || url.indexOf('cdn.aigens.com.cn') > 0 || url.indexOf('cdn.aigens.cn') > 0) && width) {
            var suffix = '?x-oss-process=image/resize';
            if (crop) {
                suffix += ',m_fill';
            }
            url += suffix + ',w_' + width;
        }
        if (url.indexOf('http://') == 0 && url.indexOf('localhost') == -1) {
            url = url.replace('http:', 'https:');
        }
        if (Data.imageHost) {
            var replace = 'lh3.googleusercontent.com';
            var i = url.indexOf(replace);
            if (i > 0) {
                var path = url.substring(i + replace.length, url.length);
                url = Data.imageHost + path;
            }
        }
        return url;
    };
    return MobileBaseItemPage;
}(MobileBasePage));
export { MobileBaseItemPage };
