<!--
  Generated template for the DineIn page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->



<ion-content padding class="no-bg">

    <div class="ad-img-box" (click)="$event.stopPropagation();adClicked()">

        <img class="ad" src="{{getImage()}}"/>
        <ion-button class="close-btn" (click)="$event.stopPropagation();dismissClicked()">
            <ion-icon name="close" color="dark"></ion-icon>
        </ion-button>
    </div>

</ion-content>
