var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
/**
 * Generated class for the TranscationsConfirmPopupPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
var TransactionsConfirm = /** @class */ (function (_super) {
    __extends(TransactionsConfirm, _super);
    function TransactionsConfirm(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.unlocked = false;
        _this.locale = _this.t.currentLang;
        if (_this.getNavParams('order')) {
            _this.order = _this.getNavParams('order');
            _this.orderId = _this.order.id;
        }
        _this.handler = _this.getNavParams('handler');
        return _this;
    }
    TransactionsConfirm.prototype.ngOnInit = function () {
        console.log('ngOnInit TranscationsConfirmPopupPage');
    };
    TransactionsConfirm.prototype.unlockedHandler = function (event) {
        console.log(event);
        this.unlocked = event;
        if (this.unlocked) {
            this.handler();
        }
    };
    TransactionsConfirm.prototype.dismiss = function () {
        this.modalController.dismiss();
    };
    return TransactionsConfirm;
}(MobileBasePage));
export { TransactionsConfirm };
