/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pre-order.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./pre-order";
var styles_PreOrderDialog = [i0.styles];
var RenderType_PreOrderDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_PreOrderDialog, data: {} });
export { RenderType_PreOrderDialog as RenderType_PreOrderDialog };
export function View_PreOrderDialog_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "ion-card", [["class", "card-box"]], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 10, "div", [["class", "top-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "ion-slides", [["autoplay", "1500"], ["pager", ""]], null, null, null, i2.View_IonSlides_0, i2.RenderType_IonSlides)), i1.ɵdid(4, 49152, null, 0, i3.IonSlides, [i1.ChangeDetectorRef, i1.ElementRef], { pager: [0, "pager"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "ion-slide", [], null, null, null, i2.View_IonSlide_0, i2.RenderType_IonSlide)), i1.ɵdid(6, 49152, null, 0, i3.IonSlide, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "ion-slide", [], null, null, null, i2.View_IonSlide_0, i2.RenderType_IonSlide)), i1.ɵdid(10, 49152, null, 0, i3.IonSlide, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, 0, 4, "div", [["class", "btn-ok"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "ion-button", [["color", "success"], ["expand", "block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(15, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(16, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 4, 0, currVal_0); var currVal_3 = "success"; var currVal_4 = "block"; _ck(_v, 15, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("pages.pre-order.page1")), ""); _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("pages.pre-order.page2")), ""); _ck(_v, 11, 0, currVal_2); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("buttons.ok")); _ck(_v, 16, 0, currVal_5); }); }
export function View_PreOrderDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-pre-order", [], null, null, null, View_PreOrderDialog_0, RenderType_PreOrderDialog)), i1.ɵdid(1, 114688, null, 0, i5.PreOrderDialog, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreOrderDialogNgFactory = i1.ɵccf("page-pre-order", i5.PreOrderDialog, View_PreOrderDialog_Host_0, {}, {}, []);
export { PreOrderDialogNgFactory as PreOrderDialogNgFactory };
