import { Category, Data, Item, ItemGroup, Member, Offer, Order, OrderItem, Period, Spot, Store } from 'aigens-ng-core';
import { ConfigService } from '../services/config.service';
import { Http } from '@angular/http';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { SystemConfigService } from '../framework/services/systemConfig.service';
import { UUID } from 'angular2-uuid';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AiService extends BaseService {

    aq: AQuery;

    order: Order;
    email: string;
    member: any = {};
    firstName: string;
    lastName: string;
    address: any = {};
    phone: string;
    count: number = 0;
    total: number = 0;
    spot: string;
    session: string;
    redirectOrderId: any = null;
    upSellingSession: any;
    pickUpTime: string;
    pickUpLabel: string;
    maxPickupTime: string;
    uuid: string;
    currency: string = "HKD";
    sign: string = "$";
    mode: string = "byod";
    selectedDineinTakeoutPrompt: string = "firstView";
    urlMode: string;
    selfPickUp: boolean = false;
    delivery: boolean = false;
    takeout: boolean = false;
    isBK = false;
    isLP = false;
    type: string;
    store: Store;
    storeId: number;
    deviceId: string;
    currentPeriod: Period;
    hiddenNav = false;
    hideTotal = false;
    comeInHasSession = false;
    hasServiceChargeOrTax = false;
    isCourt = false;
    lpWords: string;
    preOrder = false;
    chargeDesc: string;
    selectedReward: Offer;
    passcode;
    login = false;
    isStaff = false;
    isHomeButton = false;
    surveyUrl: string;
    surveyExtBrowser: boolean;
    courtId: number;
    orderExpire: number;
    settings = {};

    //cache of main item quantities
    quantities = {};
    promoCodeGift =  [];

    constructor(private http: HttpClient, public configs: ConfigService) {
        super();

        this.aq = new AQuery(http, configs);
    }

    getRecommend(member: Member): Observable<any> {

        var url = "/api/v1/ai/recommend.json";
 
        var aq = this.aq;
        aq.url = url;
        aq.method = "post";
        if(member)
        {
            aq.body = member;
            return aq.auth(true).getJson().map(jo => jo['data']);
        }else
        {
            aq.body = member;
            return aq.auth(false).getJson().map(jo => jo['data']);
        }
        
    }
 


}
