/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-conditions.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./terms-conditions";
import * as i8 from "@ionic/storage";
var styles_TermsConditionsPage = [i0.styles];
var RenderType_TermsConditionsPage = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsConditionsPage, data: {} });
export { RenderType_TermsConditionsPage as RenderType_TermsConditionsPage };
function View_TermsConditionsPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-item", [["class", "checkbox-box"], ["no-lines", ""]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "ion-checkbox", [["color", "success"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleIonChange($event.target.checked) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.marketing = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonCheckbox_0, i2.RenderType_IonCheckbox)), i1.ɵdid(3, 16384, null, 0, i3.BooleanValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.BooleanValueAccessor]), i1.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(8, 49152, null, 0, i3.IonCheckbox, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "ion-label", [["class", "check-box-label"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(10, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(11, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.marketing; _ck(_v, 5, 0, currVal_7); var currVal_8 = "success"; _ck(_v, 8, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("pages.terms-conditions.agree-text", _co.input)); _ck(_v, 11, 0, currVal_9); }); }
export function View_TermsConditionsPage_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(2, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "div", [["class", "dismiss-btn"], ["tappable", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ion-icon", [["color", "light-text"], ["name", "md-close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(5, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(7, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "div", [["class", "iframe-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "iframe", [["frameborder", "0"], ["id", "iframe"], ["style", "height:100%;width:100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "ion-footer", [], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(11, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TermsConditionsPage_1)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 4, "div", [["class", "btn-box"], ["text-center", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "ion-button", [["color", "success"], ["expand", "block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(16, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(17, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "light-text"; var currVal_1 = "md-close"; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _co.needToAgreeMarketing; _ck(_v, 13, 0, currVal_2); var currVal_3 = "success"; var currVal_4 = "block"; _ck(_v, 16, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_5 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("pages.terms-conditions.confirm")); _ck(_v, 17, 0, currVal_5); }); }
export function View_TermsConditionsPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-terms-conditions", [], null, null, null, View_TermsConditionsPage_0, RenderType_TermsConditionsPage)), i1.ɵdid(1, 245760, null, 0, i7.TermsConditionsPage, [i1.Injector, i3.NavParams, i8.Storage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsConditionsPageNgFactory = i1.ɵccf("page-terms-conditions", i7.TermsConditionsPage, View_TermsConditionsPage_Host_0, {}, {}, []);
export { TermsConditionsPageNgFactory as TermsConditionsPageNgFactory };
