var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BrowserService } from './browser.service';
import { Charge, Data } from 'aigens-ng-core';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "./browser.service";
var PaymentService = /** @class */ (function (_super) {
    __extends(PaymentService, _super);
    function PaymentService(http, configService, browserService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configService = configService;
        _this.browserService = browserService;
        _this.hasActivePayment = false;
        _this.supported = {};
        _this.aq = new AQuery(http, configService);
        return _this;
    }
    PaymentService.prototype.preload = function (store, callback) {
        var _this = this;
        if (!store) {
            return;
        }
        if (!store.brand) {
            return;
        }
        if (!store.brand['payments']) {
            return;
        }
        var payments = store.brand['payments'];
        var method = {};
        var mid;
        var loadedUrl = {};
        payments.forEach(function (payment) {
            var gateway = payment['gateway'];
            var param = {};
            var url;
            var callbackHandler;
            if (payment['merchantId']) {
                mid = payment['merchantId'];
            }
            if (gateway === 'stripe') {
                console.log('loading stripe');
                url = 'https://js.stripe.com/v3/';
                callbackHandler = function () {
                    console.log('script loaded');
                    _this.prepare(store, callback, payment.key);
                };
            }
            // Legacy code for host checkout
            /*if (gateway === 'mpgs' && payment['method'] !== 'creditcard') {
                param['defer'] = '';
                param['data-complete'] = 'completeCallback';
                param['data-error'] = 'errorCallback';
                param['data-cancel'] = 'cancelCallback';
                url = 'https://ap-gateway.mastercard.com/checkout/version/45/checkout.js';
                callbackHandler = () => {
                    callback();
                };
                console.log('loading mpgs');

            }*/
            if (gateway === 'mpgs' && (payment['method'] === 'creditcard' || payment['method'] === 'session')) {
                param['defer'] = '';
                var apiHost = payment['APIHost'];
                var version = payment['version'];
                url = (apiHost ? apiHost : 'https://ap-gateway.mastercard.com') + '/form/version/' + (version ? version : '48') + '/merchant/' + mid + '/session.js';
                console.log('use mpgs host', apiHost ? apiHost : 'https://ap-gateway.mastercard.com');
                console.log('use mpgs version', version ? version : '48');
                callbackHandler = function () {
                    callback();
                };
                console.log('loading mpgs');
            }
            if (url && !loadedUrl[url]) {
                _this.configService.loadScript(url, callbackHandler, param);
                loadedUrl[url] = true;
            }
        });
    };
    PaymentService.prototype.courtPreload = function (p, callback, store) {
        var _this = this;
        var payments = p;
        var method = {};
        var mid;
        var loadedUrl = {};
        payments.forEach(function (payment) {
            var gateway = payment['gateway'];
            var param = {};
            var url;
            var callbackHandler;
            if (payment['merchantId']) {
                mid = payment['merchantId'];
            }
            if (gateway === 'stripe') {
                console.log('loading stripe');
                url = 'https://js.stripe.com/v3/';
                callbackHandler = function () {
                    console.log('script loaded');
                    _this.prepare(store, callback, payment.key);
                };
            }
            // Legacy code for host checkout
            /*if (gateway === 'mpgs' && payment['method'] !== 'creditcard') {
                param['defer'] = '';
                param['data-complete'] = 'completeCallback';
                param['data-error'] = 'errorCallback';
                param['data-cancel'] = 'cancelCallback';
                url = 'https://ap-gateway.mastercard.com/checkout/version/45/checkout.js';
                callbackHandler = () => {
                    callback();
                };
                console.log('loading mpgs');

            }*/
            if (gateway === 'mpgs' && (payment['method'] === 'creditcard' || payment['method'] === 'session')) {
                param['defer'] = '';
                var apiHost = payment['APIHost'];
                var version = payment['version'];
                url = (apiHost ? apiHost : 'https://ap-gateway.mastercard.com') + '/form/version/' + (version ? version : '48') + '/merchant/' + mid + '/session.js';
                console.log('use mpgs host', apiHost ? apiHost : 'https://ap-gateway.mastercard.com');
                console.log('use mpgs version', version ? version : '48');
                callbackHandler = function () {
                    callback();
                };
                console.log('loading mpgs');
            }
            if (url && !loadedUrl[url]) {
                _this.configService.loadScript(url, callbackHandler, param);
                loadedUrl[url] = true;
            }
        });
    };
    PaymentService.prototype.getStripe = function () {
        return this.stripe;
    };
    PaymentService.prototype.prepare = function (store, callback, stripeKey) {
        if (!store.pos) {
            return;
        }
        if (this.stripeKey) {
            this.checkPaymentMethodAvailable();
            return;
        }
        this.stripeKey = this.configService.get('stripe');
        if (stripeKey) {
            this.stripeKey = stripeKey;
        }
        console.log('stripe key...', this.stripeKey, stripeKey);
        var stripe = Stripe(this.stripeKey);
        var pos = store.pos;
        console.log('payments?', pos);
        this.stripe = stripe;
        this.checkPaymentMethodAvailable();
    };
    PaymentService.prototype.checkPaymentMethodAvailable = function () {
        var _this = this;
        var stripe = this.stripe;
        if (!stripe) {
            this.hasActivePayment = true;
            return;
        }
        var paymentRequest = stripe.paymentRequest({
            country: 'HK',
            currency: 'hkd',
            total: {
                label: 'Aigens',
                amount: 400
            }
        });
        paymentRequest.canMakePayment().then(function (result) {
            console.log('can make payment', result);
            if (result) {
                _this.hasActivePayment = true;
            }
            else {
                _this.hasActivePayment = false;
            }
        });
    };
    PaymentService.prototype.listenToken = function (payName, store, grandTotal, callback) {
        var amount;
        if (store.currency === 'JPY') {
            amount = Math.floor(grandTotal * 1);
        }
        else {
            amount = Math.floor(grandTotal * 100);
        }
        var stripe = this.getStripe();
        var paymentRequest = stripe.paymentRequest({
            country: store.country.toUpperCase(),
            currency: store.currency.toLowerCase(),
            total: {
                label: payName,
                amount: amount
            }
        });
        this.listen(paymentRequest, callback);
        return paymentRequest;
    };
    PaymentService.prototype.payStripeCharge = function (token, charge) {
        var url = '/api/v1/pay/charge.json';
        var params = {};
        params['type'] = charge.type;
        params['token'] = token;
        params['amount'] = charge.amount;
        params['currency'] = charge.currency;
        params['groupId'] = '100';
        params['email'] = charge.email;
        params['subtype'] = charge.subtype;
        params['method'] = charge.method;
        if (charge.payeeId) {
            params['payeeId'] = charge.payeeId;
        }
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Charge, jo['data']); }));
    };
    PaymentService.prototype.canApplePay = function () {
        var aps = window.ApplePaySession;
        console.log('apple pay session', aps);
        if (aps) {
            if (aps.canMakePaymentsWithActiveCard) {
                return this.hasActivePayment;
            }
        }
        return false;
    };
    PaymentService.prototype.canAndroidPay = function () {
        if (this.browserService.ios) {
            return false;
        }
        if (window.PaymentRequest) {
            return this.hasActivePayment;
        }
        return false;
    };
    PaymentService.prototype.postApplePaySession = function (appleServerUrl, domain) {
        var url = this.configService.get('applePay'); // "https://pay-dot-aigensstoreapp.appspot.com/api/v1/pay/applesession.json";
        var body = { url: appleServerUrl, domain: domain };
        console.log(body);
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    PaymentService.prototype.listen = function (paymentRequest, callback) {
        var _this = this;
        var stripe = this.getStripe();
        paymentRequest.on('token', function (ev) {
            console.log('token!!', ev);
            callback(ev);
        });
        var elements = stripe.elements();
        var prButton = elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
        });
        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then(function (result) {
            console.log('can make payment?', result);
            if (result) {
                _this.supported = result;
                console.log('can make payment', result);
                prButton.mount('#payment-request-button');
                console.log('prButton', prButton);
            }
            else {
                // document.getElementById('payment-request-button').style.display = 'none';
                console.log('cannot pay');
            }
        });
    };
    PaymentService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.BrowserService)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}(BaseService));
export { PaymentService };
