var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { Injector } from '@angular/core';
/**
 * Generated class for the DineIn page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */
var MemberPopupDialog = /** @class */ (function (_super) {
    __extends(MemberPopupDialog, _super);
    function MemberPopupDialog(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        console.log('construct Popup');
        return _this;
    }
    MemberPopupDialog.prototype.dismissClicked = function () {
        this.modalController.dismiss(null);
        // this.navController.pop({animate: false});
        console.log('dismiss?');
    };
    MemberPopupDialog.prototype.registration = function () {
        this.modalController.dismiss(true);
    };
    return MemberPopupDialog;
}(MobileBasePage));
export { MemberPopupDialog };
