var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base/base-service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { MemberService } from './member.service';
import { AQuery } from '../base/aquery';
import { Data, Member } from 'aigens-ng-core';
import { map, share } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "./member.service";
var MemberApiService = /** @class */ (function (_super) {
    __extends(MemberApiService, _super);
    function MemberApiService(http, configs, memberService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.memberService = memberService;
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    MemberApiService.prototype.login = function (username, password, type, countryCode, brandId) {
        var _this = this;
        var url = '/api/v1/store/login.json?type=' + type;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { 'username': username, 'password': password };
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        if (brandId) {
            params['brandId'] = brandId;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            console.log('jadd login jo:', jo);
            _this.memberService.member = Data.toData(Member, jo['data']);
            return _this.memberService.member;
        }));
    };
    MemberApiService.prototype.FBloginOrRegistration = function (facebookId, token, join, storeId, brandId, marketing) {
        if (join === void 0) { join = false; }
        var url = '/api/v1/store/flogin.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {
            facebookId: facebookId,
            token: token,
            join: join,
            brandId: brandId,
            marketing: marketing,
            storeId: storeId
        };
        if (join) {
            params['type'] = 'join';
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Member, jo['data']); }));
    };
    MemberApiService.prototype.googleLogin = function (googleId, token) {
        var _this = this;
        var url = '/api/v1/store/glogin.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { 'googleId': googleId, 'token': token };
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            _this.memberService.member = Data.toData(Member, jo['data']);
            return _this.memberService.member;
        }));
    };
    MemberApiService.prototype.requestPassword = function (email, resetPageUrl) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { email: email, host: resetPageUrl, action: 'request' };
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.sendSms = function (countryCallingCodes, phone, store) {
        var url = '/api/v1/menu/member.json?verify=before';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { countryCode: countryCallingCodes, phone: phone };
        if (store && store.brand && store.brand.crmId) {
            params['crmId'] = store.brand.crmId;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.verifySms = function (smsCode, countryCallingCodes, phone) {
        var url = '/api/v1/menu/member/sms.json?action=verify';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { countryCode: countryCallingCodes, code: smsCode, phone: phone };
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.requestResetCode = function (receivedAddress, type, countryCode) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {};
        params['action'] = 'requestOtp';
        params['type'] = type;
        params[type] = receivedAddress;
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.verifyResetCode = function (type, receivedAddress, token, countryCode) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {};
        params['action'] = 'verifyByOtp';
        params['type'] = type;
        params[type] = receivedAddress;
        params['token'] = token;
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.resetPassword = function (password, confirmPassword, token) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {};
        params['action'] = 'resetByOtp';
        params['password'] = password;
        params['confirm'] = confirmPassword;
        params['token'] = token;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.resetPasswordWithOldPassword = function (oldPassword, password, confirmPassword) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var member = this.memberService.getMember();
        var params = {};
        params['action'] = 'resetInApp';
        params['original'] = oldPassword;
        params['password'] = password;
        params['confirm'] = confirmPassword;
        if (member) {
            params['session'] = member.sessionId;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.registeration = function (registerInfo) {
        var url = '/api/v1/menu/member.json';
        // var params = {
        //     email: email,
        //     password: password,
        //     token: token,
        //     storeId:storeId
        // };
        var params = registerInfo;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Member, jo['data']); }));
    };
    MemberApiService.prototype.join = function (storeId, brandId, marketing, marketingOrderPlace) {
        if (marketingOrderPlace === void 0) { marketingOrderPlace = false; }
        var url = '/api/v1/reward/activity.json';
        var member = this.memberService.getMember();
        var headers = {};
        var params = {
            brandId: brandId,
            type: 'join',
            marketing: marketing,
            storeId: storeId,
            marketingOrderPlace: marketingOrderPlace
        };
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.editProfile = function (registerInfo) {
        var url = '/api/v1/menu/member.json';
        // var params = {
        //     email: email,
        //     password: password,
        //     token: token,
        //     storeId:storeId
        // };
        var params = registerInfo;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;
        return aq.auth(true).getJson().pipe(map(function (jo) {
            var user = Data.toData(Member, jo['data']);
            return user;
        }));
    };
    MemberApiService.prototype.checkingMembership = function (brandId) {
        var _this = this;
        var url = '/api/v1/reward/membership.json?brandId=' + brandId;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            _this.memberService.hasMembership = jo['data'].length > 0;
            return jo['data'];
        }));
    };
    MemberApiService.prototype.getRedeemableRewards = function (brandId) {
        var url = "/api/v1/reward/reward.json?type=stamp&brandId=" + brandId;
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.auth(true);
        return this.aq.getJson().pipe(share(), map(function (jo) {
            return jo['data'];
        }));
    };
    MemberApiService.prototype.redeemReward = function (brandId, rewardId) {
        var url = "/api/v1/reward/redeemstamps.json?rewardId=" + rewardId + "&brandId=" + brandId;
        this.aq.url = url;
        this.aq.method = 'post';
        this.aq.auth(true);
        return this.aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    MemberApiService.ngInjectableDef = i0.defineInjectable({ factory: function MemberApiService_Factory() { return new MemberApiService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.MemberService)); }, token: MemberApiService, providedIn: "root" });
    return MemberApiService;
}(BaseService));
export { MemberApiService };
