import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Brand, Link, Offer, OpeningHours, Period, Reward, Slot, Store} from 'aigens-ng-core';

/** to form logic  start */

const countryCallingCodes = {'SG': '65', 'HK': '852', 'MO': '853', 'MY': '60', 'CN': '86', 'TW': '886'};
const phoneLength = {'852': 8, '853': 8, '65': 8, '60': 10, '886': 10, '86': 11};

// 8-12 letters and numbers
export function passwordFormatValidator(control: AbstractControl): ValidationErrors | null {
    return isPasswordVaild(control.value) ? null : {'invalid password format': 'password invaild'};
}

export function confirmPasswordFormatValidator(control: AbstractControl): ValidationErrors | null {
    const input = control.value;
    const isValid = control.root.value['Password'] === input;
    if (!isValid) {
        return {'equalTo': {isValid}};
    } else {
        return null;
    }
}

export function isPasswordVaild(value: string): Boolean {
    let isVaild = false;
    if (value) {
        isVaild = ((/[a-z]/.test(value) && /[0-9]/.test(value)) || (/[0-9]/.test(value) && /[A-Z]/.test(value)));
    }
    return isVaild;
}

export function phoneValidator(control): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
    }
    return isPhoneNumberVaild(control.value) ? null : {'mobile phone invaild': 'phone number invaild'};
}

export function isEmptyInputValue(value: string): boolean {
    return value == null || (typeof value === 'string' && value.length === 0) || value === undefined;
}

export function isPhoneNumberVaild(value): Boolean {
    let isVaild = false;
    if (value !== undefined) {
        // 不能以 0/1 開頭
        // isVaild = value.length == 8 && /^([2-9])\d{7}/.test(value);

        const newValue = value.trim();

        if (newValue) {
            isVaild = newValue.length > 7 && /^\d+$/.test(newValue);
        } else {
            isVaild = value.length > 7 && /^\d+$/.test(value);
        }

    }
    return isVaild;

}

export function isPhoneValid(phone: string, country = 'HK'): boolean {
    let isValid = false;

    if (!onlyHasNumber(phone)) {
        return false;
    }

    if (phone !== undefined && phone !== '') {
        isValid = phone.length === getPhoneLength(country);
    }

    return isValid;
}

export function isEmailValid(value: string): boolean {
    try {
        value = value.toLowerCase();
        let isValid = false;
        if (value !== undefined) {
            const regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            //    isVaild = value.includes("@") && (value.includes(".com") || value.includes(".net"));
            isValid = regExp.test(value) && (!value.includes('.web')) && (!value.includes('..')) && value.indexOf('.') !== 0;
            isValid = isValid && !(value.includes('<') && value.indexOf('>') === value.length - 1) &&
                value.indexOf('@') === value.lastIndexOf('@') && value.indexOf(')') !== value.length - 1;
            isValid = isValid && !(/^\d+$/.test(value[value.length - 1]) && (value.length - 1 - value.lastIndexOf('.') >= 4));
        }
        return isValid;
    } catch (err) {
        return false;
    }

}

export function emailFormatValidator(control): ValidationErrors | null {
    return isEmailValid(control.value) ? null : {'email format': 'email invaild'};
}

export function firstNameFormatValidator(control: AbstractControl): ValidationErrors | null {
    return isNameVaild(control.value) ? null : {'first name format': 'first name is english and space'};
}

export function isNameVaild(username: string): boolean {
    let isVaild = false;
    if (username) {
        isVaild = /^[A-Za-z\s]+$/.test(username);
    } else {
        isVaild = true;
    }
    return isVaild;
}

export function lastNameFormatValidator(control: AbstractControl): ValidationErrors | null {
    return isNameVaild(control.value) ? null : {'last name format': 'first name is english and space'};
}

export function onlyHasNumber(string: string): Boolean {
    const regExp = /^\d+$/;
    return regExp.test(string);
}

export function getCountryCallingCode(country = 'HK'): string {
    let tmp = countryCallingCodes[country];
    if (!tmp) {
        tmp = '852';
    }
    return tmp;
}

export function getPhoneLength(country): number {
    return phoneLength[getCountryCallingCode(country)];
}

export function isUnitNumberFormat(string: string): boolean {
    const regExp = /[^A-Za-z0-9 /-]/;
    let onlyNumberAndLetterAndSpace: boolean;
    onlyNumberAndLetterAndSpace = !regExp.test(string);
    return onlyNumberAndLetterAndSpace;
}

export function asLetter(string: string): Boolean {
    const regExp = /[A-Za-z]/;
    return regExp.test(string);
}

export function hasNumber(string: string): Boolean {
    const regExp = /[0-9]/;

    return regExp.test(string);
}

export function getDateStringLabel(milliseconds: number): string {
    const date = new Date(milliseconds);
    let timeString = date.getFullYear().toString() + '-';
    if ((date.getMonth() + 1) <= 9) {
        timeString = timeString + '0' + (date.getMonth() + 1).toString() + '-';
    } else {
        timeString = timeString + (date.getMonth() + 1).toString() + '-';
    }

    if (date.getDate() <= 9) {
        timeString = timeString + '0' + date.getDate().toString();
    } else {
        timeString = timeString + date.getDate().toString();
    }

    return timeString;
}


export function currentTimeString(): string {
    return timeStringLabel(null, true);
}

export function timeStringLabel(pickUpTime?: number, withSecond: boolean = false): string {
    let date: Date;
    if (pickUpTime) {
        date = new Date(pickUpTime);
    } else {
        date = new Date();
    }
    let timeString = date.getHours() + ':';
    if (date.getMinutes() === 0) {
        timeString = timeString + '00';
    } else if (date.getMinutes() <= 9) {
        timeString = timeString + '0' + date.getMinutes();
    } else {
        timeString = timeString + date.getMinutes();
    }

    if (withSecond) {
        timeString = timeString + ':';
        if (date.getSeconds() === 0) {
            timeString = timeString + '00';
        } else if (date.getSeconds() <= 9) {
            timeString = timeString + '0' + date.getSeconds();
        } else {
            timeString = timeString + date.getSeconds();
        }
    }
    return timeString;
}

export function getClosedTime(store: Store): string {
    let storeCloseTime: string;
    const today: Date = new Date();
    if (store.openings && store.openings['weekdays']) {
        const weekday = today.getDay();
        storeCloseTime = store.openings['weekdays'][weekday]['endTime'];

    }

    if (!storeCloseTime) {
        return '24:00';
    }

    return storeCloseTime;


}

export function getPickupDelayTime(store: Store): number {
    if (!store.pos) {
        return 0;
    }
    return Number(store.pos['pickupDelay']);
}

export function getDeliveryDelayTime(store: Store): number {
    if (!store.pos) {
        return 0;
    }
    return Number(store.pos['deliveryDelay']);
}

export function getTodayMillisecondsFromTimeString(timeLabel: string): number {
    const date = new Date();
    if (!isNaN(Number(timeLabel.split(':')[0]))) {
        if (timeLabel.split(':').length === 2) {
            date.setHours(Number(timeLabel.split(':')[0]), Number(timeLabel.split(':')[1]), 0, 0);

        } else if (timeLabel.split(':').length === 3) {
            date.setHours(Number(timeLabel.split(':')[0]), Number(timeLabel.split(':')[1]), Number(timeLabel.split(':')[2]), 0);

        } else if (timeLabel.split(':').length === 4) {
            date.setHours(Number(timeLabel.split(':')[0]), Number(timeLabel.split(':')[1]), Number(timeLabel.split(':')[2]), Number(timeLabel.split(':')[3]));
        }
    } else {
        return 0;
    }

    return Number(timeLabel.split(':')[0]) === 0 ? (date.getTime() + (24 * 60 * 60000)) : date.getTime();
}

export function isAvailableOffer(offer: Offer): boolean {
    const current = new Date().getTime();
    const start = offer.start ? offer.start : current;
    const end = offer.expire ? offer.expire : current;

    return start <= current && end >= current;

}

export function isAvailableReward(reward: Reward): boolean {
    const current = new Date().getTime();
    const start = reward.start ? reward.start : current;
    const end = reward.end ? reward.end : current;

    return start <= current && end >= current;

}

export function isAvailablePeriod(store, period: Period, now: Date) {
    // var today: Date = new Date();
    // today.setHours(0, 0, 0, 0);
    // var diff = now.getTime() - today.getTime();
    // var start:number;
    // var end:number;

    // //console.log("diff", diff);
    // if(period.openings && period.openings.weekdays){
    //     var [dateStart,startString,dateEnd,endString] = this.getWeekdayStartAndEnd(period);
    //     start = dateStart.getTime() - today.getTime();
    //     end = dateEnd.getTime() - today.getTime();
    // }else{
    //     if (!period.start || !period.end) return false;

    //     start = period.start;
    //     end = period.end;

    // }


    // return diff >= start && diff < end;
    if (period.openings) {

        console.log('isAvailable : check by opening rules');

        const open = OpeningHours.isTimeOpen(store.brand.openings, period.openings, now.getTime());

        return open;

    } else {

        // console.log("diff", diff);

        if (!period.start || !period.end) {
            return false;
        }

        console.log('isAvailable : check by start & end');

        return Period.isTimeOpen(period, now.getTime());
    }
}

export function getWeekdayStartAndEnd(period: Period): [Date, string, Date, string] {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    const weekday = period['savedWeekday'] !== null ? period['savedWeekday'] : today.getDay();
    const weekdays = period.openings.weekdays;
    let start: Date = new Date();
    let end: Date = new Date();
    let startTime: string;
    let endTime: string;
    if (weekdays[weekday]['startTime'] && weekdays[weekday]['endTime']) {
        startTime = weekdays[weekday]['startTime'];
        endTime = weekdays[weekday]['endTime'];

    } else {
        startTime = period.startTime;
        endTime = period.endTime;

    }
    start.setHours(Number(startTime.split(':')[0]), Number(startTime.split(':')[1]), 0, 0);
    end.setHours(Number(endTime.split(':')[0]), Number(endTime.split(':')[1]), 0, 0);
    if (today.getDay() !== weekday) {
        if (end < start) {
            start = new Date(start.getTime() - 24 * 60 * 60000);

        } else {
            start = new Date(start.getTime() - 24 * 60 * 60000);
            end = new Date(end.getTime() - 24 * 60 * 60000);

        }
    } else {
        if (end < start) {
            console.log('stepped over time to next day!!!');
            end = new Date(end.getTime() + 24 * 60 * 60000);
        }

    }

    return [start, startTime, end, endTime];

}

export function hasHoliday(brand: Brand, oh: OpeningHours, time = new Date().getTime()) {

    if (!oh) {
        return null;
    }


    if (oh.overwrites) {
        for (const slot of oh.overwrites) {
            if (Slot.isWithinDayRange(slot, time)) {
                console.log('within overwrites', slot);
                return slot;
            }
        }
    }

    if (brand.openings) {

        if (brand.openings.ph1) {
            for (const slot of brand.openings.ph1) {
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log('within holiday1', slot);
                    return oh.holidays[0];
                }
            }


        }

        if (brand.openings.ph2) {
            for (const slot of brand.openings.ph2) {
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log('within holiday2', slot);
                    return oh.holidays[1];
                }
            }
        }

    }

    return null;
}

export function isPeriodClosed(store: Store, period: Period, currentTime: number): boolean {
    if (!period) {
        return false;

    } else {
        const preStop = period['prestop'] ? period['prestop'] : 0;

        if (preStop === 1440) {
            return true;
        }
        let endTime = new Date();
        const holidaySlot = hasHoliday(store.brand, period.openings);

        if (holidaySlot) {
            const startTime = new Date();
            // endTime.setHours(Number(holidaySlot.endTime.split(":")[0]), Number(holidaySlot.endTime.split(":")[1]));
            startTime.setHours(Number(holidaySlot.startTime.split(':')[0]), Number(holidaySlot.startTime.split(':')[1]), 0, 0);
            endTime.setHours(Number(holidaySlot.endTime.split(':')[0]), Number(holidaySlot.endTime.split(':')[1]), 0, 0);
            if (endTime < startTime) {
                console.log('stepped over time to next day!!!');
                if (new Date() > endTime) {
                    endTime = new Date(endTime.getTime() + 24 * 60 * 60000);
                }
            }

        } else if (period.openings && period.openings.weekdays) {
            const [dateStart, startString, dateEnd, endString] = getWeekdayStartAndEnd(period);
            endTime = dateEnd;
        } else {
            const startTime = new Date();

            startTime.setHours(Number(period.startTime.split(':')[0]), Number(period.startTime.split(':')[1]), 0, 0);
            endTime.setHours(Number(period.endTime.split(':')[0]), Number(period.endTime.split(':')[1]), 0, 0);
            if (endTime < startTime) {
                console.log('stepped over time to next day!!!');
                if (new Date() > endTime) {
                    endTime = new Date(endTime.getTime() + 24 * 60 * 60000);
                }
            }

        }
        console.log('endTime:' + (endTime.getTime() - preStop * 60000));
        console.log('currentTime:' + currentTime);
        return (endTime.getTime() - preStop * 60000) < currentTime;

    }

}

export function getTandCUrl(brand: Brand, name: string): string {
    if (!brand.links) {
        return null;
    }
    const link = brand.links[name] as Link;
    if (!link) {
        return null;
    }

    let url = link.url;

    if (!url) {
        return null;
    }

    if (url.indexOf('http://') === 0 && url.indexOf('localhost') === -1) {
        url = url.replace('http:', 'https:');
    }

    return url;
}

/** to form logic  end */
