import * as i0 from "@angular/core";
var SdkBaseService = /** @class */ (function () {
    function SdkBaseService() {
        this.testing = false;
    }
    SdkBaseService.prototype.getServiceName = function () {
        return null;
    };
    SdkBaseService.prototype.isAvailable = function () {
        var ios = window['webkit'];
        var serviceName = this.getServiceName();
        if (typeof ios !== 'undefined') {
            if (ios.messageHandlers[serviceName]) {
                return true;
            }
        }
        else {
            if (typeof window[serviceName] !== 'undefined') {
                return true;
            }
        }
        return false;
    };
    SdkBaseService.prototype.callNative = function (method, params, callback) {
        if (callback === void 0) { callback = null; }
        var serviceName = this.getServiceName();
        var cbName = null;
        if (callback) {
            cbName = serviceName + method + 'Cb';
            window[cbName] = function (str) {
                callback(JSON.parse(str));
                window[cbName] = null;
            };
        }
        if (cbName) {
            params['_callback'] = cbName;
        }
        params['_method'] = method;
        var webkit = window['webkit'];
        if (webkit) {
            webkit.messageHandlers[serviceName].postMessage(params);
        }
        else {
            console.log('calling android');
            console.log(window[serviceName]);
            var input = JSON.stringify(params);
            window[serviceName].postMessage(input);
            console.log('done');
        }
    };
    SdkBaseService.ngInjectableDef = i0.defineInjectable({ factory: function SdkBaseService_Factory() { return new SdkBaseService(); }, token: SdkBaseService, providedIn: "root" });
    return SdkBaseService;
}());
export { SdkBaseService };
