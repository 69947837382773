var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InventoryManager } from 'aigens-ng-core';
import { ConfigService } from './config.service';
import { BaseService } from '../base/base-service';
import * as firebase from 'firebase';
import 'firebase/database';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "aigens-ng-core/dist/manager/inventory-manager";
var LiveDataService = /** @class */ (function (_super) {
    __extends(LiveDataService, _super);
    function LiveDataService(http, configs, inventoryManager) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.inventoryManager = inventoryManager;
        _this.inventory = {};
        _this.refs = [];
        if (_this.configs.isChina()) {
            return _this;
        }
        var config = _this.configs.config.getFirebaseConfig();
        if (!_this.configs.firebase) {
            _this.configs.firebase = firebase.initializeApp(config);
            firebase.auth().signInAnonymously();
        }
        _this.database = firebase.database();
        return _this;
    }
    /*
    setListener(cb: Function){
        this.listener = cb;
    }*/
    LiveDataService.prototype.listenInventory = function (storeId) {
        var _this = this;
        // var ref = this.fbdb.database.ref("menu/inventory/" + storeId);
        var ref = this.database.ref('menu/inventory/' + storeId);
        this.refs.push(ref);
        ref.on('value', function (snapshot) {
            _this.inventory = snapshot.val();
            _this.inventoryManager.inventory = _this.inventory;
            console.log('inv update', storeId, _this.inventory);
        });
    };
    LiveDataService.prototype.unlistenAll = function () {
        for (var _i = 0, _a = this.refs; _i < _a.length; _i++) {
            var ref = _a[_i];
            ref.off();
        }
        this.refs = [];
    };
    LiveDataService.ngInjectableDef = i0.defineInjectable({ factory: function LiveDataService_Factory() { return new LiveDataService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.InventoryManager)); }, token: LiveDataService, providedIn: "root" });
    return LiveDataService;
}(BaseService));
export { LiveDataService };
