import {MobileBasePage} from '../../core/base/mobile-base-page';
import {Component, Injector} from '@angular/core';

/**
 * Generated class for the DineIn page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'page-freeGift',
    templateUrl: 'freeGift.html',
    styleUrls: ['freeGift.scss']
})
export class FreeGiftDialog extends MobileBasePage {


    constructor(private injector: Injector) {
        super(injector);
        console.log('construct Popup');
    }

    toMemberPage() {
        this.modalController.dismiss(true);
    }

    dismissClicked() {
        this.modalController.dismiss(null);
    }

}
