/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./popup";
var styles_PopupDialog = [i0.styles];
var RenderType_PopupDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupDialog, data: {} });
export { RenderType_PopupDialog as RenderType_PopupDialog };
export function View_PopupDialog_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "ion-content", [["class", "no-bg"], ["padding", ""]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "div", [["class", "ad-img-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.adClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "ad"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "ion-button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(6, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "ion-icon", [["color", "dark"], ["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(8, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"], name: [1, "name"] }, null)], function (_ck, _v) { var currVal_1 = "dark"; var currVal_2 = "close"; _ck(_v, 8, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getImage(), ""); _ck(_v, 4, 0, currVal_0); }); }
export function View_PopupDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-popup", [], null, null, null, View_PopupDialog_0, RenderType_PopupDialog)), i1.ɵdid(1, 245760, null, 0, i4.PopupDialog, [i1.Injector, i3.NavParams], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupDialogNgFactory = i1.ɵccf("page-popup", i4.PopupDialog, View_PopupDialog_Host_0, {}, {}, []);
export { PopupDialogNgFactory as PopupDialogNgFactory };
