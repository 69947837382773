import { Animation } from '@ionic/core';

export function myLeaveAnimation(AnimationC: Animation, baseEl: HTMLElement): Promise<Animation> {

    const baseAnimation = new AnimationC();

    const wrapperAnimation = new AnimationC();
    wrapperAnimation.addElement(baseEl.querySelector('.toast-wrapper'));

    wrapperAnimation.beforeStyles({ 'opacity': 1 })
        .fromTo('opacity', '1', '0');

    return Promise.resolve(baseAnimation
        .addElement(baseEl)
        .easing('cubic-bezier(0.36,0.66,0.04,1)')
        .duration(400)
        .beforeAddClass('show-modal')
        .add(wrapperAnimation));

}