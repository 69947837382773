import {Store} from 'aigens-ng-core';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import 'firebase/database';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs';
import {AQuery} from '../base/aquery';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MonitoringService extends BaseService {

    database: any;
    refs: any[] = [];
    refsByKey: any = {};
    enable = false;
    aq: AQuery;

    constructor(private http: HttpClient, public configs: ConfigService) {
        super();

        if (this.configs.isChina()) {
            return;
        }
        const config = this.configs.config.getFirebaseConfig();
        if (!this.configs.firebase) {
            this.configs.firebase = firebase.initializeApp(config);
            firebase.auth().signInAnonymously();
        }
        this.database = firebase.database();
        this.aq = new AQuery(http, configs);

    }

    get numberOfRefs(): number {
        return Object.keys(this.refsByKey).length;
    }

    getStoreStatus(storeId: any): Observable<any> {

        const host = this.configs.get('statusHost');
        if (!host) {
            return null;
        }

        const url = host + '/api/v1/store/status.json?storeId=' + storeId;

        const aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => jo));
    }

    listenOrder(nodeName: string, listCb: Function, updateCb: Function, removeCb?: Function) {


        console.log('checking nodeName', nodeName);
        let ref = this.database.ref(nodeName);
        console.log('checking ref', ref);
        this.refsByKey[nodeName] = ref;
        // console.log("checking this.refsByKey[attValue]", this.refsByKey[attValue]);


        ref.once('value', (snapshot) => {

            let data = snapshot.val();

            console.log('once update', data);
            listCb(data);
        }, error => {
            console.error(error);

            listCb(null);
        });

        ref.on('value', (childSnapshot, prevChildKey) => {

            let data = childSnapshot.val();

            console.log('child update', data);
            // callback(data);
            updateCb(data);
        }, error => {
            console.error(error);


        });

        if (removeCb) {
            ref.on('value', (childSnapshot, prevChildKey) => {

                let data = childSnapshot.val();

                console.log('child remove', data);
                // callback(data);
                removeCb(data);
            });

        }

    }

    listenOrderStatus2(nodeName: any, listCb: Function, updateCb: Function, removeCb?: Function) {
        this.listenOrder(nodeName, listCb, updateCb, removeCb);
    }

    listenStoreOrder(nodeName: any, listCb: Function, updateCb: Function, removeCb?: Function) {
        this.listenOrders(nodeName, listCb, updateCb, removeCb);
    }

    listenOrders(nodeName: string, listCb: Function, updateCb: Function, removeCb?: Function) {
        let ref = this.database.ref(nodeName);

        // ref.on('value', (snapshot) => {

        //     let data = snapshot.val();
            
        //     console.log('listenOrders once update', data);
        //     listCb(data);
        // }, error => {
        //     console.error(error);

        //     listCb(null);
        // });

        ref.orderByChild('status').equalTo('received').on('value', (childSnapshot, prevChildKey) => {

            let data = childSnapshot.val();

            console.log('listenOrders child update', data);
            // callback(data);
            // updateCb(data);
            listCb(data);
        }, error => {
            console.error(error);
            listCb(null);


        });

        if (removeCb) {
            ref.orderByChild('status').equalTo('received').on('child_removed', (childSnapshot, prevChildKey) => {

                let data = childSnapshot.val();

                console.log('listenOrders child remove', data);
                // callback(data);
                removeCb(data);
            });

        }
    }

    listenStoreKioskStatus(store: Store, offlineHandler: any, onlineHandler: any) {

        console.log('listen kiosk status 2');

        /*

        this.listenStation("storeId", store.id, (result) => {
            this.monitorResultHandling(result, offlineHandler, onlineHandler);
        }, () => { });
        */

        this.getStoreStatus(store.id).subscribe(
            status => {

                if (status && status.order) {
                    onlineHandler();
                } else {
                    offlineHandler();
                }

            },
            err => {
                console.log(err);
                offlineHandler();
            }
        );

    }

    listenStoreStarPrintStatus(storeId: number, handler: any) {
        this.listenStation('storeId', Number(storeId), (result) => {
            handler(result);
        }, (result) => {
            handler(result);
        });
    }

    monitorResultHandling(result: any, offlineHandler: any, onlineHandler: any) {
        let canOrder = false;
        const currentTime = new Date().getTime();
        for (const key in result) {
            const status = result[key]['status']['status'];
            console.log(currentTime - result[key]['status']['datetime']);
            if (status == 'online' && currentTime - result[key]['status']['datetime'] < 15 * 60000) {
                canOrder = true;
                break;
            }
        }

        const allKioskOffline = !canOrder;

        this.unlistenAll();
        console.log(result);
        if (allKioskOffline) {
            offlineHandler();
        } else {
            onlineHandler();
        }
    }

    listenStation(attName: string, attValue: any, listCb: Function, updateCb: Function) {
        this.listen('status', attName, attValue, listCb, updateCb);

    }

    listenPrinterOrder(printAddress: string, orderNo: string, updateCb: Function) {
        const node = 'printer/' + printAddress + '/orders/' + orderNo;
        this.listenValueChange(node + '/status', 'status' + orderNo, 'status', updateCb);
        this.listenValueChange(node + '/error', 'error' + orderNo, 'error', updateCb);

    }

    listenValueChange(nodeName: string, id: string, attName: string, updateCb: Function) {
        const ref = this.database.ref(nodeName);

        this.refsByKey[id] = ref;

        ref.on('value', (snapshot) => {

            const data = snapshot.val();
            console.log('update', data);
            // callback(data);
            const params = {id: id, type: attName, value: data};
            updateCb(params);
            console.log(this.refsByKey);

        }, error => {
            console.error(error);


        });
    }

    listen(nodeName: string, attName: string, attValue: any, listCb: Function, updateCb: Function, removeCb?: Function) {


        const ref = this.database.ref(nodeName);

        this.refsByKey[attValue] = ref;

        ref.orderByChild(attName).equalTo(attValue).once('value', (snapshot) => {

            const data = snapshot.val();

            console.log('once update', data);
            listCb(data);
        }, error => {
            console.error(error);

            listCb(null);
        });

        ref.orderByChild(attName).equalTo(attValue).on('child_changed', (childSnapshot, prevChildKey) => {

            const data = childSnapshot.val();

            console.log('child update', data);
            // callback(data);
            updateCb(data);
        }, error => {
            console.error(error);


        });

        if (removeCb) {
            ref.orderByChild(attName).equalTo(attValue).on('child_removed', (childSnapshot, prevChildKey) => {

                const data = childSnapshot.val();

                console.log('child remove', data);
                // callback(data);
                removeCb(data);
            });

        }

    }

    listenStationByKey(keyValue: string, listCb: Function, updateCb: Function) {
        const ref = this.database.ref('status/' + keyValue);

        this.refsByKey.push(ref);

        ref.once('value', (snapshot) => {

            const data = snapshot.val();

            console.log('detail once update', data);
            listCb(data);
        });

        ref.on('value', (snapshot) => {

            const data = snapshot.val();

            console.log('detail on update', data);
            // callback(data);
            updateCb(data);
        });
    }

    unlistenAll() {

        for (const id in this.refsByKey) {
            if (this.refsByKey[id]) {
                this.refsByKey[id].off();
            }
        }

        this.refsByKey = {};

    }

    unlistenByKey(id: string) {

        if (this.refsByKey[id]) {
            this.refsByKey[id].off();

            console.log('detail listen is stop');

            delete this.refsByKey[id];

        }
    }

    unlistenOrderPair(orderNo: string, callback?: any) {
        const statusId = 'status' + orderNo;
        const errorId = 'error' + orderNo;
        this.unlistenByKey(statusId);
        this.unlistenByKey(errorId);
        if (callback) {
            callback();
        }
        console.log(this.refsByKey);
    }

    isOrderMonitoring(orderNo: string): boolean {
        const statusId = 'status' + orderNo;
        const errorId = 'error' + orderNo;

        return this.isMonitoring(statusId) && this.isMonitoring(errorId);

    }

    isMonitoring(id: string): boolean {
        const tmp = this.refsByKey[id];
        if (tmp) {
            return true;
        } else {
            return false;
        }
    }

}
