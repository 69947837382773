import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {Data, Item, ItemGroup, ModifierBuilder, OrderItem, Store} from 'aigens-ng-core';
import {MobileBasePage} from '../../core/base/mobile-base-page';
import {NavParams} from '@ionic/angular';

@Component({
    selector: 'modifier-dialog',
    templateUrl: 'modifier.html',
    styleUrls: ['modifier.scss']
})
export class ModifierDialog extends MobileBasePage implements OnInit {

    group: ItemGroup;
    itemMap: any;
    store: Store;
    orderItem: OrderItem;
    builder: ModifierBuilder;
    confirm = false;
    modifiableItems: Item[];
    currentItem: Item;
    tmps: Item[];
    mmap = {};
    absentItemsId: string[] = [];
    currentIndex: number;
    itemsCount = {};
    item: Item;

    constructor(public injector: Injector, public navParams: NavParams, private cd: ChangeDetectorRef) {

        super(injector);

        this.orderItem = this.navParams.get('orderitem');
        this.store = this.navParams.get('store');
        this.group = this.navParams.get('group');
        this.item = this.navParams.get('item');
        this.itemMap = this.navParams.get('itemMap');
        this.item = this.navParams.get('item');
        if (!this.store) {
            this.store = this.orderManager.store;
        }
        this.builder = new ModifierBuilder(this.store, this.orderItem);
        if (this.group) {
            const items = this.group.items.map((item) => {
                this.itemsCount[item.id] = item.quantity;
                const tmpItem: Item = Data.toData(Item, {...item});
                const tmpMgroups = this.itemMap[item.id].mgroups;
                tmpItem.mgroups = Data.toDataArray(ItemGroup, [...tmpMgroups]);
                return tmpItem;
            });
            this.modifiableItems = items.filter(item => item.mgroups && item.mgroups.length > 0);
        }
        if (this.item) {
            this.modifiableItems = [];
            this.modifiableItems.push(this.item);
        }

        this.modifiableItems.forEach((item) => {
            item.mgroups.forEach((mgroup) => {
                // let tmp = mgroup.items.filter(item => item.absentId != null && item.absentId != "")
                // tmp.forEach((item)=>{
                //     this.absentItemsId.push(item.absentId);

                // })
                mgroup.items.forEach((mitem) => {
                    if (mitem.absentId != null && mitem.absentId !== '') {
                        this.absentItemsId.push(mitem.absentId);
                    }

                    if (mitem.mgroup && this.has(item, mgroup, mitem)) {
                        this.mmap[mitem.mgroup] = mitem;
                    }

                });

                mgroup.items.forEach((mitem) => {
                    if (mgroup.defaultId && mitem.id === mgroup.defaultId) {
                        this.itemClicked(item, mgroup, mitem, true);
                    }

                });

            });
        });

        this.currentIndex = this.navParams.get('showLastOne') ? this.modifiableItems.length - 1 : 0;
        this.currentItem = this.modifiableItems[this.currentIndex];

        console.log(this.modifiableItems);

    }

    ngOnInit() {
        console.log('ngOnInit VariationPage');
    }

    getSelectRules(igroup: ItemGroup): string {
        if (!igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return '';
        }
        const min = Number(igroup.min);
        const max = Number(igroup.max);
        if (min === max && min === 0) {
            return '';
        }
        // 請選擇{{count}}項
        if (min === max) {
            return this.t.instant('pages.item-select.select-items', {count: min});
        }
        // 加配項目
        if (min === 0 && max === 99) {
            return this.t.instant('pages.item-select.optional');
        }
        // 請選最多max項
        if (min === 0 && max !== 99) {
            return this.t.instant('pages.item-select.chooseAtMost', {max: max});
        }
        // 請選至少{{min}}項
        if (min !== 0 && max === 99) {
            return this.t.instant('pages.item-select.chooseUp', {min: min});
        }
        // 請選{{min}} - {{max}}項
        if (min < max && max !== 99) {
            return this.t.instant('pages.item-select.chooseTo', {min: min, max: max});
        }
        return '';
    }


    dismissClicked(confirem?: boolean) {
        this.modalController.dismiss(confirem);

    }

    isGroupSelected(item: Item, igroup: ItemGroup): boolean {
        if (!item || !igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return false;
        }
        let min = Number(igroup.min);
        let max = Number(igroup.max);
        let items = igroup.items || [];
        let isSelect = false;
        let quantities = 0;

        for (let tmpItem of items) {
            quantities += this.builder.getQuantity(item, igroup, tmpItem);
        }
        if (min > 0) {
            isSelect = quantities >= igroup.min;
        } else if (quantities > 0) { /// min = 0
            isSelect = true;
        }
        return isSelect;
    }

    itemClicked(item: Item, group: ItemGroup, mItem: Item, isPreselect: boolean = false) {
        const max: number = group.max;
        const contain: boolean = this.has(item, group, mItem);

        if (mItem.baseQty > 0) {
            const count = this.builder.getQuantity(item, group, mItem);
            if (count < mItem.max) {
                this.builder.plus(item, group, mItem);
            }
        } else if (group.repeat) {
            if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                this.builder.plus(item, group, mItem);
            }
        } else {
            if (max === 1) {
                this.builder.clearForItem(item, group);
                if (!contain) {
                    this.builder.setMod(item, group, mItem, 1);

                }
            } else if (max > 1) {
                let q: number;
                if (contain) {
                    q = 0;
                    if (mItem.mgroup) {
                        this.mmap[mItem.mgroup] = null;
                    }
                } else {

                    if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                        q = 1;
                    } else {
                        q = 0;
                    }
                }

                if (mItem.mgroup && q === 1) {
                    this.clearSameMGroup(item, group, mItem);
                }
                this.builder.setMod(item, group, mItem, q);
            }

        }

        if (!isPreselect && group.min > 0 && this.isItemCompleted(item) && this.currentIndex + 1 < this.modifiableItems.length) {
            this.selectItem(this.modifiableItems[this.currentIndex + 1], this.currentIndex + 1);
        }

        if (!isPreselect) {
            this.cd.detectChanges();

        }


    }

    clearSameMGroup(item: Item, group: ItemGroup, mItem: Item) {
        const i = this.mmap[mItem.mgroup];
        if (i) {
            this.builder.setMod(item, group, i, 0);
        }


        this.mmap[mItem.mgroup] = mItem;

    }

    confirmClicked() {
        const orderManager = this.multOrderManager.enableMutlOrder ? this.multOrderManager.getOrderManager(this.store.id) : this.orderManager;
        if (OrderItem.isSet(this.orderItem) || this.orderItem.quantity === 1) {
            const oi = this.builder.build();
            orderManager.refreshOrderItem(oi);
        } else {
            const duplicated: OrderItem = orderManager.duplicateOrderItem(this.orderItem);
            duplicated.quantity--;
            const oi = this.builder.build();
            oi.quantity = 1;
            orderManager.refreshOrderItem(oi);
        }
        this.dismissClicked(true);
    }

    has(item: Item, group: ItemGroup, mItem: Item): boolean {
        // console.log(mItem.name + ":" + this.builder.getQuantity(item, group, mItem));
        return this.builder.getQuantity(item, group, mItem) > 0;
    }

    canAdd(item: Item, group: ItemGroup, mItem?: Item): boolean {
        const max: number = group.max;
        let quantities = 0; // this.builder.getSelectedModifierCount(item);
        for (const tmpItem of group.items) {
            quantities += this.builder.getQuantity(item, group, tmpItem);
        }
        if (mItem && this.mmap[mItem.mgroup] && mItem.id !== this.mmap[mItem.mgroup].id) {
            return true;
        }

        return max > quantities;
    }

    minusClicked(item: Item, group: ItemGroup, mItem: Item) {
        const count = this.builder.getQuantity(item, group, mItem);
        if (count > mItem.min) {
            this.builder.minus(item, group, mItem);

        }
        this.cd.detectChanges();

    }

    selectItem(item, index) {
        this.currentItem = item;
        this.currentIndex = index;
    }

    isItemCompleted(item: Item): boolean {
        let completed = true;
        const mgroups: ItemGroup[] = this.itemMap[item.id].mgroups;
        if (mgroups) {
            for (let i = 0; i < mgroups.length; i++) {
                let quantities = 0;

                for (const tmpItem of mgroups[i].items) {
                    quantities += this.builder.getQuantity(item, mgroups[i], tmpItem);
                }

                if (mgroups[i].min > 0) {
                    completed = quantities >= mgroups[i].min;

                } else {
                    completed = true;
                }

                if (!completed) {
                    break;
                }


            }
        }

        return completed;
    }

    isAllCompleted(items: Item[]): boolean {
        // return item.mgroups
        // .filter(mgroup => mgroup.min > 0)
        // .some((mgroup)=>{
        //     return mgroup.items.length >= mgroup.min;
        // });
        let completed = true;
        for (const item of items) {
            completed = this.isItemCompleted(item);
            if (!completed) {
                break;
            }

        }
        return completed;


    }

}
