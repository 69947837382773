import {NgModule} from '@angular/core';
import {ScrollHideDirective} from './scroll-hide/scroll-hide';
import {ParallaxHeaderDirective} from './parallax-header/parallax-header';
import {ThrottleClickDirective} from './throttle-click/throttle-click';

@NgModule({
    declarations: [
        ScrollHideDirective,
        ThrottleClickDirective,
        ParallaxHeaderDirective
    ],
    imports: [],
    exports: [
        ScrollHideDirective,
        ThrottleClickDirective,
        ParallaxHeaderDirective
    ]
})
export class DirectivesModule {
}
