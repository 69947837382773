var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { Injector, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
var SecureVerifyPage = /** @class */ (function (_super) {
    __extends(SecureVerifyPage, _super);
    // constructor(public viewCtrl: ViewController, injector: Injector, params: NavParams,public startupService: StartupService
    function SecureVerifyPage(injector, storage) {
        var _this = _super.call(this, injector) || this;
        _this.storage = storage;
        _this.body = '';
        _this.body = _this.getNavParams('body');
        return _this;
    }
    SecureVerifyPage.prototype.ngOnInit = function () {
        var _this = this;
        window.closeFrame = function (result) {
            var status = result ? 'success' : 'failed';
            _this.modalController.dismiss(status);
            // this.dismiss();
        };
        this.secureFrame.nativeElement.contentWindow.document.write(this.body);
        this.secureFrame.nativeElement.contentWindow.document.close();
        // this.secureFrame.nativeElement.contentWindow.onclose = () => {
        //         console.log("onclose!");
        //         // this.dismiss();
        //
        // };this.secureFrame.nativeElement.contentWindow.document.onchange = () => {
        //         console.log("onchange!");
        //         // this.dismiss();
        //
        // };this.secureFrame.nativeElement.contentWindow.onunload = ($event) => {
        //         console.log("onunload!",$event);
        //         // this.dismiss();
        //
        // };
        // this.secureFrame.nativeElement.contentWindow.document.body.onchange = ($event) => {
        //         console.log("verifed!");
        //         // this.dismiss();
        // };
    };
    SecureVerifyPage.prototype.dismiss = function (agree) {
        console.log('dismiss');
        if (agree) {
            this.modalController.dismiss();
        }
        else {
            this.modalController.dismiss();
        }
        // this.push(BrandStoreListPage, {brand:5637145314197504});
    };
    return SecureVerifyPage;
}(MobileBasePage));
export { SecureVerifyPage };
