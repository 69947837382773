// import { ViewController } from 'ionic-angular';
import { Component, Injector, OnInit } from '@angular/core';
// import { KioskBasePage } from '../../base/kiosk-base-page';
// import { CurrencyService } from '../../services/currency-service';
import { Recommendation, Store } from 'aigens-ng-core';
import { Category } from 'aigens-ng-core';
import { ItemGroup } from 'aigens-ng-core';
import { Item } from 'aigens-ng-core';

// import { LocalStorageHelper } from "../../utility/local-storage-helper";
import { Order } from 'aigens-ng-core';
import { MathUtils } from 'aigens-ng-core';

import {InventoryManager, OrderManager} from 'aigens-ng-core';
import {MobileBasePage} from '../../core/base/mobile-base-page';

import {NavParams} from '@ionic/angular';
import { MobileBaseItemPage } from 'src/app/core/base/mobile-base-item-page';
/**
 * Generated class for the RecommendList page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({

    selector: 'page-unlockPopup',
    templateUrl: 'unlockPopup.html',
    styleUrls: ['unlockPopup.scss']

})

export class UnlockPopupDialog extends MobileBaseItemPage implements OnInit {

    store: Store;
    storeId: number;
    recommendList: Category;
    order: Order;
    // localStorageHelper: LocalStorageHelper;

    animateQtyMap = {};
    newTotal = 0;
    addItems: Item[] = []; // all single items in recommendation list
    qtyTag: number[] = [];

    category: any;
    itemgroups: any;
    // pref: PreferenceService;
    count = 0;
    isGift = false; // deal with free items
    max: number;
    showImage = true;
    displayTitle = '';
    recommendations: Recommendation[];
    orderManager: OrderManager;
    inventoryManager: InventoryManager;

    constructor(private injector: Injector, public navParams: NavParams) {
        super(injector);

        // this.localStorageHelper = this.injector.get(LocalStorageHelper);
        // this.pref = this.injector.get(PreferenceService);
        this.recommendList = this.navParams.get('recommendList');
        this.order = this.orderManager.order;
        this.store = this.navParams.get('store');
        this.addItems = this.navParams.get('items');
        this.isGift = this.navParams.get('isGift');
        this.max = this.navParams.get('max'); // for unlocks
        this.recommendations = this.navParams.get('recommendations'); // for AI recommendations

        if (this.max) {
            this.displayTitle = this.t.instant('pages.item-select.chooseAtMost', { max: this.max });
        }
        this.init();
    }

    init() {
        if (this.recommendations) {
            this.addItems = [];
            this.recommendations.forEach(rm => {
               this.addItems.push(rm.item);
               this.qtyTag.push(0);
               if (this.showImage && (!rm.item.images || !rm.item.images['default'])) this.showImage = false;
            });
            this.count = this.recommendations.length;

        } else {

            for (let i = 0; i < this.addItems.length; i++) {
                this.qtyTag[i] = 0;
                if (!this.addItems[i].parentId || this.addItems[i].parentId == this.addItems[i].id) {
                    this.count++;
                    if (this.showImage && (!this.addItems[i].images || !this.addItems[i].images['default'])) this.showImage = false;
                }
            }

            this.category = this.recommendList;
            this.itemgroups = this.recommendList.groups;
        }
    }



    updateUI() {

    }

    private isVariationParent(i: number) {

        let item = this.addItems[i];

        let exist: boolean = this.isParent(item);

        if (exist) {

            let items = item.variations.slice(0);

            items.push(item);

            // this.presentDialog2(VariationDialog, { items: items, store: this.store, takeout: this.order.takeout }, (selected) => {

            //     if (selected) {
            //         let j: number;
            //         for (j = 0; j < this.addItems.length; j++) {
            //             if (this.addItems[j].id == selected.id) break;
            //         }

            //         this.onAddClicked(i, j);
            //     }
            // });
        }

        return exist;
    }

    onOrderChanged(order: Order) {
        // this.localStorageHelper.setOrder(order);
    }

    isSet(category: Category, item: Item): boolean {

        if (item.groups && item.groups.length > 0) return true;
        if (item.pgroup) return true;
        return category.groups.length > 1;
    }

    isSoldout(item: Item): boolean {
        // let now = this.getNow();
        return !this.isParent(item) && (this.inventoryManager.isSoldout(item)); // || !this.menuManager.isAvailableCombo(this.store, this.category, item, this.order.takeout, now));
    }

    private isParent(item: Item) {

        return item.variations != null && item.variations.length > 0;
    }

    // i: parent, j:selected
    onAddClicked(i: number, j: number) {


        this.animateQtyMap['#' + this.addItems[i].id] = false;

        setTimeout(() => {
            this.animateQtyMap['#' + this.addItems[i].id] = true;
        }, 10);

        this.addItems[j].quantity++;
        this.qtyTag[i]++;
        this.newTotal += this.round(this.addItems[j].price);

    }

    checkTotalQty() {
        let qty = 0;
        this.addItems.forEach(item => {
            qty += item.quantity;
        });
        console.log('selected qty:', qty);
        return qty;
    }

    addClicked(i: number) {

        // this.playClick();

        if (this.max && this.checkTotalQty() == this.max) {
            if (this.max !== 1) return;

            // clear other selection if single select
            for (let index = 0; index < this.addItems.length; index++) {
                while (this.qtyTag[index] > 0) this.minusItem(index);
            }
        }
        let item = this.addItems[i];

        if (this.checkTotalQty() == this.max) return;

        // deal with group items
        if (this.isVariationParent(i)) return;

        // soldout
        if (this.isSoldout(item)) return;

        // single item
        this.onAddClicked(i, i);

        console.log('add single item#' + i, this.addItems[i].name);
    }



    private removeVariation(i: number) {
        let item = this.addItems[i];

        let items = item.variations.slice(0);
        items.push(item);

        this.qtyTag[i] = 0;
        items.forEach(i => {
            for (let j = 0; j < this.addItems.length; j++) {
                if (this.addItems[j].id == i.id) {
                    this.newTotal -= this.round(this.addItems[j].price * this.addItems[j].quantity);
                    this.addItems[j].quantity = 0;
                }
            }
        });
    }

    minusItem(i: number) {

        if (this.isParent(this.addItems[i])) {
            this.removeVariation(i);
        } else {
            this.addItems[i].quantity--;
            this.qtyTag[i]--;
            this.newTotal -= this.round(this.addItems[i].price);
        }
    }

    removeClicked(i: number) {
        if (this.qtyTag[i] <= 0) return;

        // this.playClick();

        console.log('remove clicked');

        this.minusItem(i);
    }

    clearClicked() {

        // this.playClick();

        this.updateUI();

        this.modalController.dismiss('cancel');

    }

    okClicked() {

        // this.playClick();
        if (this.round(this.newTotal) <= 0 && !this.isGift) return;

        /*
        this.presentDialog(ConfirmDialog, this.t.instant("pages.recommend-list.confirmTitle"), null, null, data => {

            if (data == "ok") {

                console.log('Buy clicked');
                let order: Order = this.orderManager.order;

                this.addItems.forEach(item =>{
                    for (let i = 0; i < item.quantity; i++) {
                        let now: number = this.getNow();
                        let group: ItemGroup = this.itemgroups[0];
                        let oi = this.orderManager.addSingleItem(this.category, group, item);
                    }
                });

                this.onOrderChanged(this.orderManager.order);

                this.updateUI();

                this.viewCtrl.dismiss("ok");
            } else {
                console.log('Cancel clicked');
                this.viewCtrl.dismiss(null);
            }
        });
        */

        console.log('Buy clicked');
        let order: Order = this.orderManager.order;


        for (let index = 0; index < this.addItems.length; index++) {

            let item: Item = this.addItems[index];

            for (let i = 0; i < item.quantity; i++) {

                let group: ItemGroup;
                if (this.recommendations) {
                    group = this.recommendations[index].itemgroup;
                    this.category = this.recommendations[index].category;
                } else {
                    group = this.itemgroups[0];
                }

                let oi = this.orderManager.addSingleItem(this.category, group, item);
                if (this.isGift) {
                    if (!order.gifts) order.gifts = [];
                    order.gifts.push(oi);
                }
            }
        }

        this.onOrderChanged(this.orderManager.order);

        this.updateUI();

        this.modalController.dismiss('ok');
    }

    round(value: number) {

        return MathUtils.round(value, 2);
    }

    getItemName(item: Item) {

        let name: string = item.name;

        if (this.isParent(item)) {
            name = item.title;
        }

        if (!name || name.length == 0) {
            name = item.name;
        }

        return name;
    }

}
