var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Color from 'color';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var defaults = {
    primary: '#3880ff',
    secondary: '#0cd1e8',
    tertiary: '#7044ff',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    dark: '#222428',
    medium: '#989aa2',
    light: '#f4f5f8'
};
function contrast(color, ratio) {
    if (ratio === void 0) { ratio = 0.6; }
    color = Color(color);
    console.log('color', color);
    return color.isDark() ? color.darken(ratio) : color.lighten(ratio);
}
function CSSTextGenerator(colors) {
    colors = __assign({}, defaults, colors);
    var primary = colors.primary, secondary = colors.secondary, tertiary = colors.tertiary, success = colors.success, warning = colors.warning, danger = colors.danger, dark = colors.dark, medium = colors.medium, light = colors.light;
    var shadeRatio = 0.1;
    var tintRatio = 0.1;
    return "\n    --ion-color-base: " + light + ";\n    --ion-color-contrast: " + dark + ";\n\n    --ion-color-primary: " + primary + ";\n    --ion-color-primary-rgb: 56,128,255;\n    --ion-color-primary-contrast: #ffffff;\n    --ion-color-primary-contrast-rgb: 255,255,255;\n    --ion-color-primary-shade:  " + Color(primary).darken(shadeRatio) + ";\n\n    --ion-color-warning: " + warning + ";\n    --ion-color-warning-rgb: 56,128,255;\n    --ion-color-warning-contrast: " + contrast(warning) + ";\n    --ion-color-warning-contrast-rgb: 255,255,255;\n    --ion-color-warning-shade:  " + Color(warning).darken(shadeRatio) + ";\n\n    --ion-color-success: " + success + ";\n    --ion-color-success-rgb: 56,128,255;\n    --ion-color-success-contrast: #ffffff;\n    --ion-color-success-contrast-rgb: 255,255,255;\n    --ion-color-success-shade:  " + Color(success).darken(shadeRatio) + ";\n\n    --ion-color-danger: " + danger + ";\n    --ion-color-danger-rgb: 56,128,255;\n    --ion-color-danger-contrast: #ffffff;\n    --ion-color-danger-contrast-rgb: 255,255,255;\n    --ion-color-danger-shade:  " + Color(danger).darken(shadeRatio) + ";\n\n    --ion-color-dark: " + dark + ";\n    --ion-color-dark-rgb: 56,128,255;\n    --ion-color-dark-contrast: #ffffff;\n    --ion-color-dark-contrast-rgb: 255,255,255;\n    --ion-color-dark-shade:  " + Color(dark).darken(shadeRatio) + ";\n\n    --ion-color-medium: " + medium + ";\n    --ion-color-medium-rgb: 56,128,255;\n    --ion-color-medium-contrast: #ffffff;\n    --ion-color-medium-contrast-rgb: 255,255,255;\n    --ion-color-medium-shade:  " + Color(medium).darken(shadeRatio) + ";\n\n    --ion-color-light: " + light + ";\n    --ion-color-light-rgb: 56,128,255;\n    --ion-color-light-contrast: #000000;\n    --ion-color-light-contrast-rgb: 255,255,255;\n    --ion-color-light-shade:  " + Color(light).darken(shadeRatio) + ";\n\n\n\n    // omitted other styles, see full source code\n";
}
var ThemeService = /** @class */ (function () {
    function ThemeService(document) {
        this.document = document;
        // storage.get('theme').then(cssText => {  // <--- GET SAVED THEME
        //     this.setGlobalCSS(cssText);
        // });
    }
    // Override all global variables with a new theme
    ThemeService.prototype.setStoreTheme = function (store) {
        console.log('set theme', store.colors);
        // let overwrites = {};
        if (store.colors) {
            // let colors = store.colors;
            // // primary: store.colors['primary'],
            // //     light: store.colors['light'],
            // //     dark: store.colors['dark'],
            //  if(colors['primary'])
            //      overwrites['primary'] =
            var cssText = CSSTextGenerator(store.colors);
            this.setGlobalCSS(cssText);
            console.log('generate theme', cssText);
        }
        // this.storage.set('theme', cssText); // <--- SAVE THEME HERE
    };
    ThemeService.prototype.setFavicon = function (iconUrl) {
        var link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
        link['type'] = 'image/x-icon';
        link['rel'] = 'shortcut icon';
        link['href'] = iconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
    };
    ThemeService.prototype.setConfigTheme = function (colors) {
        console.log('set theme', colors);
        // let overwrites = {};
        if (colors) {
            // let colors = store.colors;
            // // primary: store.colors['primary'],
            // //     light: store.colors['light'],
            // //     dark: store.colors['dark'],
            //  if(colors['primary'])
            //      overwrites['primary'] =
            var cssText = CSSTextGenerator(colors);
            this.setGlobalCSS(cssText);
            console.log('generate theme', cssText);
        }
        // this.storage.set('theme', cssText); // <--- SAVE THEME HERE
    };
    // Define a single CSS variable
    ThemeService.prototype.setVariable = function (name, value) {
        this.document.documentElement.style.setProperty(name, value);
    };
    ThemeService.prototype.setGlobalCSS = function (css) {
        this.document.documentElement.style.cssText = css;
    };
    ThemeService.ngInjectableDef = i0.defineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.inject(i1.DOCUMENT)); }, token: ThemeService, providedIn: "root" });
    return ThemeService;
}());
export { ThemeService };
