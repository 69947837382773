import {Component, OnInit} from '@angular/core';
import {Member, Store} from 'aigens-ng-core';
import {MemberService} from '../../core/services/member.service';
import {OrderService} from '../../core/services/order.service';
import {ModalController, NavParams} from '@ionic/angular';

/**
 * Generated class for the DineIn page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'page-survey',
    templateUrl: 'survey.html',
    styleUrls: ['survey.scss']
})
export class SurveyDialog implements OnInit {
    iframe: any;
    finish = false;
    member: Member;

    deHeight: number;

    constructor(public memberService: MemberService, public orderService: OrderService, public modalController: ModalController, public navParams: NavParams) {
        // super(injector);
        this.member = this.memberService.getMember();
    }

    ngOnInit() {

        console.log('ngOnInit Popup');
        // just for demo
        this.iframe = document.getElementById('target-dom-node');
        const store: Store = this.navParams.get('store');
        let url = this.orderService.surveyUrl; // 'https://sodacardsurvey.typeform.com/to/iYhZHS';
        if (this.member && store) {
            url = url + '?id=' + this.member.id + '&brand_id=' + store.brandId;
        }
        console.log('url:', url);
        this.iframe.addEventListener('load', () => {
            console.log('new method');
            this.checking();
        });
        this.iframe.setAttribute('src', url);

        this.deHeight = screen.height;
    }

    // just for demo
    checking() {
        console.log('finish:', this.finish);
        if (this.finish) {
            this.dismissClicked();
        }
        this.finish = true;

    }

    dismissClicked() {

        this.modalController.dismiss(null);

        // this.navController.pop({animate: false});

        console.log('dismiss?');

    }

    closelicked() {
        console.log('close clicked');
    }

}
