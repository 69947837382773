import {MobileBasePage} from '../../core/base/mobile-base-page';
import {Component, Injector} from '@angular/core';

/**
 * Generated class for the DineIn page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'page-memberPopup',
    templateUrl: 'memberPopup.html',
    styleUrls: ['memberPopup.scss']
})
export class MemberPopupDialog extends MobileBasePage {


    constructor(private injector: Injector) {
        super(injector);
        console.log('construct Popup');
    }



    dismissClicked() {

        this.modalController.dismiss(null);

        // this.navController.pop({animate: false});

        console.log('dismiss?');

    }

    registration() {
        this.modalController.dismiss(true);
    }


}
